import { Injectable } from '@angular/core';
import { environment } from './environment';
import { StorageService } from '../app/core/services/storage.service';
import { APP_CONSTANTS } from '../app/core/constants/app-constants';
import { ClientConfig } from '../app/core/interface/client-config';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private clientConfig?: ClientConfig;
  constructor(private readonly storageService: StorageService) {}

  setClientConfig() {
    const clientId = this.storageService.get(APP_CONSTANTS.CLIENT_ID);
    this.clientConfig = environment.clientConfig
      ? environment.clientConfig?.find((c: ClientConfig) => c.id == clientId) ||
        environment.clientConfig[0] ||
        environment.clientConfig
      : undefined;
  }

  resetClientConfig() {
    this.clientConfig = environment.clientConfig[0] || environment.clientConfig;
  }

  get clientId():string{
    if (!this.clientConfig) {
      this.setClientConfig();
    }
    return this.clientConfig?.id ?? '';
  }

  get clientConfigSettings(): ClientConfig[] {
    return environment.clientConfig || [];
  }

}
